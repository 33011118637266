// storeData.js
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';

// Save suggestions to Firestore
const saveSuggestions = async (keyword, suggestions) => {
  const docRef = await addDoc(collection(db, 'keyword_suggestions'), {
    keyword,
    suggestions,
    createdAt: new Date(),
  });
  return docRef.id;
};

// Fetch suggestions from Firestore by keyword
const fetchSuggestions = async (keyword) => {
  const q = query(collection(db, 'keyword_suggestions'), where('keyword', '==', keyword));
  const querySnapshot = await getDocs(q);

  if (!querySnapshot.empty) {
    return querySnapshot.docs[0].data().suggestions;
  }
  return null;
};

export { saveSuggestions, fetchSuggestions };
