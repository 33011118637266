import styled from 'styled-components';


const Button = styled.button`
  padding: 8px 16px;
  margin-right: 5px;
  background-color: ${props => props.$primary ? '#1E3949' : 'gray'};
  color: ${props => props.primary ? 'white' : 'white'};
  border: ${props => props.primary ? 'solid 1px black' : 'none'};
  border-radius: 4px;
  cursor: pointer;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  &:hover {
  background-color: #C3E5ED;
  color: black;
  }
`;
const ButtonLogout = styled.button`
  padding: 8px 16px;
  margin-right: 5px;
  background-color: white;
  color: red;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: red;
    color: white;
    }
`;

const ActionButtons = ({ onSubmit, onAddExtended, isLoadingExtended, exportTop10Suggestions, exportTop10AndExtended, top10Suggestions, extendedResults, handleExportToTXT, onLogout }) => {

  return (
    <div>
      <Button onClick={onSubmit} $primary>Fetch Top 10 Suggestions</Button>
      <Button onClick={onAddExtended} disabled={isLoadingExtended}>
        {isLoadingExtended ? 'Loading...' : 'Add Extended Suggestions'}
      </Button>
      <Button onClick={()=>exportTop10Suggestions(top10Suggestions)}>Export Top 10 to CSV</Button>
      <Button onClick={()=>exportTop10AndExtended(extendedResults)}>Export all to CSV</Button>
      <Button onClick={handleExportToTXT}>Copy to clipboard</Button>
      <ButtonLogout onClick={onLogout}>Logout</ButtonLogout>
    </div>
  );
};

export default ActionButtons;
