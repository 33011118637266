// Component for displaying extended results
const ExtendedResults = ({ results, hideTitles, onToggleHideTitles }) => {
  //console.log('ExtendedResults display compo:',results)
    return (
      <div style={{ flex: 1, display: "flex", flexDirection: "column", maxWidth: "450px" }}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <h3>Extended Results:</h3>
          <label style={{ marginLeft: "10px" }}>
            <input
              type="checkbox"
              checked={hideTitles}
              onChange={onToggleHideTitles}
            />
            Hide Extended Results Titles
          </label>
        </div>
  
        {results.map((result, index) => (
          <div className="extended-result" key={index}>
            {!hideTitles && <h4>{result.query}</h4>}
            <ul style={{ marginLeft: 0, paddingLeft: 0 }}>
              {result.extendendSuggestions.map((extendendSuggestion, index) => (
                <li key={index} style={{ listStyle: "none" }}>
                  {extendendSuggestion}
                </li>
              ))}
            </ul>
          </div>
              ))}
      </div>
    );
  };

export default ExtendedResults;  