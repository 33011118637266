import React, { memo, useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';

const Container = styled.article`
  border-radius: 16px;
  box-shadow: 0 30px 30px -25px rgba(65, 51, 183, 0.25);
  max-width: 300px;
  // background-color: var(--c-white);
  background-color: var(--c-white);
  padding: 10px;
  margin: 20px 0;
`;
const Inner = styled.div`
  padding: 20px;
  padding-top: 40px;
  background-color: var(--c-fair-pink);
  border-radius: 12px;
position: relative;
  overflow: hidden;
  `;
const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
`;
const CachedInfo = styled.span`
position: absolute;
top: 0;
right: 0;
background-color: var(--c-apricot);
border-radius: 99em 0 0 99em;
display: flex;
align-items: center;
padding: .625em .75em;
font-size: 1.25rem;
font-weight: 600;
color: var(--c-coffee);
`;
const Title = styled.h2`
  font-size: 1.5rem;
  color: var(--c-void);
  line-height: 1.25;
  margin-top: -30px;
`;
// Keyframes for the fade-out animation
const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;
const fadeOutStyle = css`
  animation: ${fadeOut} 1s forwards;
`;
const Info = styled.span`
  color: var(--c-charcoal);
  margin: ${(props) => (props.$visible ? '10px' : '0px')};
  // height: ${(props) => (props.$visible ? '20px' : '0px')};
  display: block;
  opacity: ${(props) => (props.$visible ? 1 : 0)};
  transition: opacity 0.5s;
  ${(props) => !props.$visible && fadeOutStyle}
`;

const List = styled.ul`
  margin-left: 0;
  padding: 0;
  list-style: none;
`;

const ListItem = styled.li`
  cursor: pointer;
  &:hover {
    color: blue;
  }
`;

// const Cached = styled.span`
//   background-color: var(--c-titan-white);
//   color: var(--c-indigo);
//   font-weight: 600;
//   font-size: 0.875rem;
//   padding: 0.5em 0.75em;
//   line-height: 1;
//   border-radius: 6px;
//   margin-left: 10px;
// `;

const ResultsList = ({ suggestions, searchQuery, onSuggestionClick, dbCached }) => {
  const [infoVisible, setInfoVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setInfoVisible(false), 3000);

    // Clear timer on unmount
    return () => clearTimeout(timer);
  }, []);

  return (
    <Container className="card">
      <Info $visible={infoVisible}>Click to extent search</Info>       
      <Inner>       
        <HeadingContainer>
          <Title>{searchQuery}</Title>
          {dbCached && <CachedInfo>Cached</CachedInfo>}
        </HeadingContainer>
        <List>
          {suggestions.map((suggestion, index) => (
            <ListItem key={index} onClick={() => onSuggestionClick(suggestion)}>
              {suggestion}
            </ListItem>
          ))}
        </List>
      </Inner>
    </Container>
  );
};

export default memo(ResultsList);
