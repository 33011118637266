import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import GoogleAutoSuggest from "./gpt/GoogleAutoSuggest";
// import GoogleAutoSuggestAPI from './gpt/GoogleAutoSuggestAPI'
// import GoogleAutoSuggWithColumns from './gpt/GoogleAutoSuggWithColumns'
// import GoogleAutoSuggToCSV from './gpt/GoogleAutoSuggToCSV'
// import registerServiceWorker from './registerServiceWorker';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// ReactDOM.render(<App />, document.getElementById('root'));
// ReactDOM.render(<GoogleAutoSuggest />, document.getElementById('root'));
// registerServiceWorker();
reportWebVitals();
