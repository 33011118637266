import React, { useEffect, useState, useCallback } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, logout } from "../firebase";
import { saveSuggestions, fetchSuggestions } from '../firebase/storeData';
import styled from 'styled-components';
import axios from 'axios';
import copy from 'clipboard-copy';

import ActionButtons from './ActionButtons';
import ResultsList from './ResultsList';
import DerivatedResults from './DerivatedResults';
import SearchInput from './SearchInput'; 
import ExtendedResults from './ExtendedResults';


//import logo from '../assets/keysugg-logo.png';

const MainContainer = styled.div`
display: flex;
flex-direction: column; 
`;
const LogoContainer = styled.div`
display: flex;
flex-direction: row; 
background-image: url('keysugg-logo.png');
background-repeat: no-repeat;
background-color: white;
background-position: center center;
width: 100%;
margin: 0 0 10px 0;
padding: 30px 0;
`;
const FormContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: start;
width 80%;
gap: 20px; 
`;
const ResultsContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: start;
margin: 10px;
padding-left: 20px;
gap: 20px; 
`;
const AllTop10Containers = styled.div`
display: flex;
flex-direction:row;
align-items: start;
align-content: stretch ;
gap: 20px; 
width: 100%;
`;

const OtherTop10Containers = styled.div`
display: flex;
flex-direction:row;
justify-content: flex-start;
align-items: start;
align-content: stretch;
height: 100%;
gap: 20px;
`;
const ExtendedContainer = styled.div`
display: flex;
flex-direction:row;
justify-content: flex-start;
align-items: start;
gap: 20px; 
`;
const Drawer = styled.div`
  position: fixed;
  top: 0;
  right: ${(props) => (props.$show ? '0' : '-250px')};
  width: 250px;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transition: right 0.3s;
  z-index: 1000;
  padding: 50px 20px;
`;
const DrawerButton = styled.button`
  position: fixed;
  top: 20px;
  right: ${(props) => (props.$show ? '250px' : '20px')};
  transition: right 0.3s;
  z-index: 1001;
  background-color: white;
  border: solid 1px blue;
  color: blue;
  font-size:12px;
  max-height: 17px:
  cursor: pointer;
  margin-left: 10px;
  padding: 1px 2px;
  border-radius: 5px;  
`;

/*
We call/store the results from Google 'suggestions'
*/

const Main = () => {
    // All states and hooks
  const [user, loading, error] = useAuthState(auth);
  const [searchQuery, setSearchQuery] = useState('');
  const [country, setCountry] = useState('fr');
  const [language, setLanguage] = useState('fr');
  const [top10Suggestions, setTop10Suggestion] = useState([]);
  const [noResult, setNoResult] = useState(false);
  const [dbCached, setDbCached] = useState(false);
  const [openedResults, setOpenedResults] = useState([]);
  const [extendedResults, setExtendedResults] = useState([]);
  const [loadingExtendedResults, setLoadingExtendedResults] = useState(false);
  const [hideExtendedResultsTitle, setHideExtendedResultsTitle] = useState(false);
  const [recentSearches, setRecentSearches] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);  

  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    // check if user is logged 
    if (!user) return navigate("/");
    // if (process.env.NODE_ENV === 'production') { if (!user) return navigate("/"); }
    // If there is an error, handle it here
    if (error) {
      console.log("Authentication error:", error.message);
      // You can display an error message to the user, redirect, or take other actions based on the error
  }       
  }, [user, loading,error, navigate]);

  // Toggle drawer visibility
  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  // Add to recent searches
  const addRecentSearch = (query) => {
    setRecentSearches((prevSearches) => {
      const newSearches = [query, ...prevSearches];
      return newSearches.slice(0, 20);
    });
  };

  // the query from the field
  const handleChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  };

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };
  // fire the search of the top 10 keywords
  const handleSubmit = (e) => {
    e.preventDefault();

    // Fetch auto-suggestions from Google API
    //fetchAutoSuggestions(); //Vanilla
    fetchOrStoreSuggestions();
    addRecentSearch(searchQuery);
  };

  // Check if a result exist in Firestore DB
  const fetchOrStoreSuggestions = async () => {
    // Check Firestore for cached suggestions
    const cachedSuggestions = await fetchSuggestions(searchQuery);
    if (cachedSuggestions) {
     // console.log('Results aleady in db',cachedSuggestions)
      setTop10Suggestion(cachedSuggestions); // populate the state
      setDbCached(true); //tells the results are from DB
    } else {
      // Fetch new suggestions from Google
      const newSuggestions = await fetchAutoSuggestions(); // trigger the Google search
      //console.log('no db results. New kw =>', newSuggestions)
      //setTop10Suggestion(newSuggestions); // already done by fetchAutoSuggestions

      // Save them to Firestore for future use
      await saveSuggestions(searchQuery, newSuggestions);
      setDbCached(false); // tells the results are not from DB
    }
  };

  // Vanilla retreive the first 10 results that Google gives. Store it in the Top10Suggestion state
  const fetchAutoSuggestions = async () => {
    try {
      const response = await axios.get(
        `https://nextjs-boilerplate-red-eight-60.vercel.app/api/${encodeURIComponent(searchQuery)}/${country}/${language}`
      );
             
      const data = response.data;
      const suggestions = data[1];
      //console.log(suggestions)
      //console.log('fetchAutoSuggestions is fired')

      // check if there is no result
      if (suggestions.length === 0) {
        setNoResult(true);
      } else {
        setNoResult(false);
        setTop10Suggestion(suggestions);
      }

      // Clear the previous opened results and Extended results
      setOpenedResults([]);
      setExtendedResults([]);
      
      return suggestions; // return results to the firetore function fetchOrStoreSuggestions to DB insertion
    } catch (error) {
      console.error(error);
    }
  };
  
  // If we click on one of the first Top10 results, search the top 10 of this keyword. (DerivatedResults)
  const handleSuggestionClick = useCallback(async (suggestion) => {
    //console.log("Clicked suggestion:", suggestion); // Check which suggestion was clicked    
    try {
      const response = await axios.get(
        `https://nextjs-boilerplate-red-eight-60.vercel.app/api/${encodeURIComponent(suggestion)}/${country}/${language}`
      );
  
      const data = response.data;
      const newSuggestions = data[1]; // The new results from the clicked keyword link
      //console.log("API Response for clicked suggestion:", newSuggestions); // Log the API response
      
      // Stack it in openedResults, keep the previous newSuggestions and make an other entry
      setOpenedResults((prevResults) => [
        ...prevResults,
        {
          query: suggestion,
          top10FromSuggestions: newSuggestions,
        },
      ]);
    } catch (error) {
      console.error("Error fetching suggestion details:", error);
    }
  },[country,language]);

  // Search the KW with a list of suffix for extendedResults 
  const addSearchWithExtended = async () => {
    setLoadingExtendedResults(true); // Set loading state to true

    const suffix = 'abcdefghijklmnopqrstuvwxyz1234567890';
    const newQueries = [];

    for (let i = 0; i < suffix.length; i++) {
      const newQuery = searchQuery + ' ' + suffix[i];
      newQueries.push(newQuery);
      //console.log('suffix',suffix[i])
    }
   //console.log('newQueries',newQueries)
    const newResults = [];

    for (let i = 0; i < newQueries.length; i++) {
      const query = newQueries[i];
      await delay(500); // Delay between requests (in milliseconds). Throttle API call.
      const response = await fetch(
        `https://nextjs-boilerplate-red-eight-60.vercel.app/api/${encodeURIComponent(query)}/${country}/${language}`
      );    

      const data = await response.json();
      const extendendSuggestions = data[1];
        //console.log('extendendSuggestions',extendendSuggestions)
      newResults.push({
        query,
        extendendSuggestions,
      });
    }

    setExtendedResults(newResults);
    //console.log('extendedResults state',newResults)
    setLoadingExtendedResults(false); // Set loading state to false once fetching is done   
  };
  // Receive the delay value to throttle the API call 
  const delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  // Function to toggle hiding extended results titles
  const toggleHideExtendedResultsTitle = () => {
    setHideExtendedResultsTitle(!hideExtendedResultsTitle);
  };

 // Function to remove a Derivated result block by index
const handleCloseResult = (indexToRemove) => {
  setOpenedResults((prevResults) => prevResults.filter((_, index) => index !== indexToRemove));
}; 

// Clear results on focus the input search field
const clearResults = () => {
  setSearchQuery('');
  setTop10Suggestion([]);
  setOpenedResults([]);
  setExtendedResults([]);
  setDbCached(false);
  setNoResult(false);
};

// Utility function to convert an array of data into CSV format
function arrayToCSV(dataArray) {
  // Convert each array to a CSV row by joining with commas
  return dataArray.map((row) => row.join(',')).join('\n');
}

// Function to download the CSV file
function downloadCSV(content, filename) {
  const blob = new Blob([content], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);

  // Create a temporary anchor element and trigger a download
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = filename;
  anchor.click();

  // Cleanup
  URL.revokeObjectURL(url);
}

// Function to export only the top10Suggestions to CSV
function exportTop10Suggestions(top10Suggestions = []) {
  // Convert the top 10 suggestions to CSV rows
  const csvContent = arrayToCSV([
    ['Keyword'], // Column header
    ...top10Suggestions.map((keyword) => [keyword]), // Rows
  ]);

  downloadCSV(csvContent, 'Top10Suggestions.csv');
}

// Function to export both top10Suggestions and extendedResults to CSV
function exportTop10AndExtended(top10Suggestions, extendedResults) {
  // Column headers
  let csvRows = [['Keyword', 'Extended Suggestions']];

  // Add top 10 suggestions
  top10Suggestions.forEach((keyword) => {
    csvRows.push([keyword, '']);
  });

  // Add extended results
  extendedResults.forEach((result) => {
    result.extendendSuggestions.forEach((suggestion) => {
      csvRows.push([result.query, suggestion]);
    });
  });

  const csvContent = arrayToCSV(csvRows);
  downloadCSV(csvContent, 'Top10AndExtended.csv');
}

const copyToClipboard = (text) => {
  const textarea = document.createElement('textarea');
  textarea.value = text;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('copy');
  document.body.removeChild(textarea);
};

const handleExportToTXT = () => {
  // Combine all the data from top10Suggestions, extendedResults, and openedResults
  const allData = [
    ...top10Suggestions,
    ...extendedResults.flatMap((result) => result.top10Suggestions),
    //...openedResults.flatMap((result) => result.top10FromSuggestions),
  ];
  //console.log("handleExportToTXT:", allData); 
  // Convert the data to CSV format
  const csvContent = allData.map((data) => data.replace(/,/g, '')).join('\n');

  // Copy the CSV content to the clipboard
  copyToClipboard(csvContent); // copy txt  
  copy(csvContent); 
};

  return (
    <MainContainer>
      <LogoContainer></LogoContainer>
      <FormContainer>
        <form onSubmit={handleSubmit}>
          <SearchInput
            searchQuery={searchQuery}
            onSearchChange={handleChange}
            country={country}
            onCountryChange={handleCountryChange}
            language={language}
            onLanguageChange={handleLanguageChange}
            clearResults={clearResults}
          />
          <ActionButtons
            onSubmit={handleSubmit}
            onAddExtended={addSearchWithExtended}
            isLoadingExtended={loadingExtendedResults}
            top10Suggestions={top10Suggestions}
            extendedResults={extendedResults}
            exportTop10Suggestions={exportTop10Suggestions}
            exportTop10AndExtended={exportTop10AndExtended}
            handleExportToTXT={handleExportToTXT}
            onLogout={logout}
          />
        </form>
      </FormContainer>
      <ResultsContainer>
        <AllTop10Containers>
          <div>
            {noResult && <p>No Result</p>}
            {top10Suggestions.length > 0 && <ResultsList suggestions={top10Suggestions} searchQuery={searchQuery} onSuggestionClick={handleSuggestionClick} dbCached={dbCached}/>}
          </div>
          <OtherTop10Containers>
            <DerivatedResults openedResults={openedResults} onCloseResult={handleCloseResult}/>
          </OtherTop10Containers>
        </AllTop10Containers>
        <ExtendedContainer>
        {extendedResults.length>0 && <ExtendedResults results={extendedResults} hideTitles={hideExtendedResultsTitle} onToggleHideTitles={toggleHideExtendedResultsTitle} />}
        </ExtendedContainer>
      </ResultsContainer>
      <DrawerButton $show={drawerVisible} onClick={toggleDrawer}>
        {drawerVisible ? 'Close Current Searches' : 'Open Current Searches'}
      </DrawerButton>
      <Drawer $show={drawerVisible}>
        <span>Current Searches</span>
        <ul>
          {recentSearches.map((search, index) => (
            <li key={index}>{search}</li>
          ))}
        </ul>
      </Drawer>      
    </MainContainer>
  );
  };

  export default Main