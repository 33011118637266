import styled from 'styled-components';

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-bottom: 5px;
  height: 1.68rem;
  margin-bottom: 30px; 
`;

const SearchField = styled.input`
  flex-grow: 1;
  padding: 6px;
  line-height: 18px;
  padding-left: 40px;
  margin-right: 5px;
  background-image: url('searchicon.png');
  background-repeat: no-repeat;
  background-position: 10px 10px;
  height: 1.68rem;
  border: 1px solid #cacaca;
  border-radius: 4px;  
`;

const Selector = styled.select`
  padding: 6px;
  margin-right: 5px;
  height: 2.60rem;
  border: 1px solid #cacaca;
  border-radius: 4px;  
`;

const SearchInput = ({ searchQuery, onSearchChange, country, onCountryChange, language, onLanguageChange, clearResults }) => {
  return (
    <InputContainer>
      <SearchField
        type="text"
        value={searchQuery}
        onChange={onSearchChange}
        onFocus={clearResults}
        placeholder="Enter your query"
      />
      <Selector onChange={onCountryChange} value={country}>
        <option value="fr">France</option>
        <option value="gb">United-Kingdom</option>
        <option value="de">Germany</option>
        <option value="it">Italy</option>
        <option value="es">Spain</option>
        <option value="ch">Switzerland</option>
        <option value="us">United-states</option>
      </Selector>
      <Selector onChange={onLanguageChange} value={language}>
      <option value="fr">French</option>
        <option value="en">English</option>
        <option value="de">German</option>
        <option value="it">Italian</option>
        <option value="es">Spanish</option>
      </Selector>
    </InputContainer>
  );
};

export default SearchInput;
